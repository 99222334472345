  <template>
  <v-row>
    <v-col cols="3">
      <currencies
        :currency.sync="currency"
        :disabled="!collaborationTaskIsNew"
        :accountId="collaborationTask.accountId"
      ></currencies>
    </v-col>
    <v-col>
      <amount-field
        :currency="currency"
        :amount.sync="amount"
        :label="$t('t.Amount')"
        :disabled="!collaborationTaskIsNew"
        :rules="rules"
        class="ml-2"
        reverse
      >
      </amount-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    AmountField: () => import('@/components/amount-field'),
    Currencies: () => import('@/components/currencies')
  },
  data () {
    return {
      required: [
        v => (v !== undefined && v !== '') || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    amount: {
      get () {
        return this.collaborationTask.amount
      },
      set: function (amount) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { amount }))
      }
    },
    currency: {
      get () {
        return this.collaborationTask.currency
      },
      set (currency) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { currency }))
      }
    },
    rules () {
      return [v => Number.isFinite(v) || this.$t('t.IsRequired')]
    }
  },
  props: {
    collaborationTask: Object,
    collaborationTaskIsNew: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 400px
</style>
